<template>
  <div class="w-sm-100 w-xs-100">
    <div class="sm-trading-content">

      <!--REDEEM NOTIFICATION BALANCE-->
      <div v-if="showRedeemNotification" class="trading-flex-column w-100 text-center white-card-md">

        <div class="mt-2">
          <check-icon size="3x" style="color:#3B9E52" class="custom-class"></check-icon>
        </div>

        <div class="mb-4 mt-2">
          <div style="display: flex;align-items: center;justify-content: center"><strong>SGD 5.00</strong></div>
          <div class="mb-1 text-success">Added to your balance</div>
        </div>

        <div class="trading-notification-border-highlighted">
          <vs-row vs-type="flex" vs-w="12" vs-justify="flex-start" vs-align="center" class="p-3">
            <div class="trading-flex-column">
              <div class="text-left">
                <small class="text-success">Current Available Balance</small>
              </div>
              <div class="text-left">
                <strong>SGD {{formatFiatPrice(availableBalance)}}</strong>
              </div>
            </div>
          </vs-row>
        </div>


      </div>


      <!--REDEEM VOUCHER FIELD-->
      <div class="white-card-md mt-2 mb-2 p-2" v-else>
          <h5 class="p-1 m-2">Redeem your <img src="/assets/trading-assets/grab-rewards-new.svg" style="padding-bottom:4px;margin:3px">Voucher</h5>

          <vs-row class="mb-2">
            <label class="control-label">
              Redeem your voucher
            </label>
            <div class="input-group">
              <input type="text"
                     class="form-control"
                     v-model="voucherCode"
              >
            </div>
          </vs-row>
          <vs-row class="mb-2">
            <button class="w-100 btn-trading-negative" @click="redeemCode()">Redeem</button>
          </vs-row>
<!--        <p>Redeem Code {{voucherCode}}</p>-->
        <div class="text-medium text-error" v-if="validationVoucherCodeError">
          Voucher code is invalid. Please insert a valid voucher code.
        </div>
      </div>

      <br>
      <vs-row class="mb-2" v-if="showRedeemNotification">
        <button class="w-100 btn-trading-positive" @click="backToRedeemForm()">Have another voucher ? Redeem again</button>
      </vs-row>
      <vs-row class="mb-2">
        <button class="w-100 btn-trading-positive" @click="goBack()">Back</button>
      </vs-row>

    </div>

  </div>
</template>
<script>
import { CheckIcon } from 'vue-feather-icons'
import {mapActions, mapGetters} from "vuex";

export default {
  name: 'redeem-voucher-page',
  components: {
    CheckIcon
  },
  data() {
    return {
      mode: process.env.VUE_APP_STAGING_ENV ? null : "production",

      showRedeemNotification: false,

      voucherCode : "",

      validationVoucherCodeError : false,

    }
  },
  watch: {},
  computed: {
    ...mapGetters(["userFiatBalanceGetter","userVoucherBalanceGetter"]),

    availableBalance(){
        let fiatBalance = this.userFiatBalanceGetter && this.userFiatBalanceGetter.find(item => item.bal_type === 'fiat')
        let voucherBalance = this.userVoucherBalanceGetter && this.userVoucherBalanceGetter.find(item => item.bal_type === 'voucher');
        let totalBalance = Number(voucherBalance.bal_amt) + Number(fiatBalance.bal_amt);
        return Number(totalBalance);
    }
  },
  metaInfo: {
    title: 'TransCrypt - Transfer Form | Send Money Faster with Cryptocurrency',
  },
  mounted() {
  console.log("Redeem Voucher page");

  },
  methods: {
    ...mapActions(["getUserBalances", "redeemCodeAction"]),

      redeemCode(){
        //validation
        if(!this.voucherCode)
        {
          console.log("test");
          this.validationVoucherCodeError = true;
        }else
        {
          this.validationVoucherCodeError = false;
        }

        if(this.validationVoucherCodeError)
        {
          return;
        }

        let payload = {};
        // payload.vcr_ref_num = this.voucherCode;
        payload.rdm_code = this.voucherCode;

        console.log("Redeem Voucher payload", payload);

        this.redeemCodeAction(payload).then(res => {

          console.log("Result of redeeming voucher in redeem voucher page",res);
          //call the balances API, to get the latest fiat + voucher balance after redemption
          this.getUserBalances().then(res => {
            console.log("User balances in the redeem voucher page ", res.data);
            this.showRedeemNotification = true;
            this.validationVoucherCodeError = false;

          }).catch(err => {
            console.log("Error getting user balances in the redeem voucher page", err.response);
          })

        }).catch(err => {
          console.log("Error in redeeming voucher in redeem voucher page", err.response);

          this.validationVoucherCodeError = true;
        })



      },

      goBack(){
        this.$router.go(-1);
      },

      backToRedeemForm(){
        this.showRedeemNotification = false;
        this.voucherCode = "";
      }

  }
}

</script>
<style>


</style>